<template>
  <v-navigation-drawer
    v-model="value"
    @input="$emit('input', $event)"
    :mini-variant="false"
    :clipped="false"
    color="secondary"
    dark
    app
    fixed
    class="d-print-none"
  >
    <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item-avatar>
        <v-img src="/logoAmer.svg" contain></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="title">AMER</v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pt-0" nav>
      <template v-for="item in items">
        <v-list-group
          v-if="item.childrens"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary"
                class="white--text"
              >
                <v-list-item-title v-text="c.title"></v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.title"
              :to="child.url"
              :exact="item.exact"
              link
              active-class="primary"
              class="white--text"
            >
              <!-- <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-content> -->
              <v-list-item-title v-text="child.title"></v-list-item-title>
              <!-- </v-list-item-content> -->
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppMenu",
  props: {
    value: Boolean,
  },
  computed: {
    items() {
      return [
        {
          title: this.$t("dashboard"),
          icon: "dashboard",
          url: "/candidate",
          exact: true,
        },
        {
          title: this.$t('Vagas_Candidatadas'),
          icon: "mdi-check-circle-outline",
          url: "/candidate/candidate/vacancies",
          exact: true,
        },
        {
          title: this.$t('Vagas_Privadas'),
          icon: "mdi-book-lock-outline",
          url: "/candidate/candidate/vacancies/private",
          exact: true,
        },
        {
          title: this.$t('interviews'),
          icon: "mdi-calendar-check-outline",
          url: "/candidate/candidate/interviews",
          exact: true,
        },
        {
          title: this.$t('tests'),
          icon: "mdi-clipboard-list-outline",
          url: "/candidate/candidate/tests",
          exact: true,
        },
      ];
    },
  },
  data: () => ({
    // items: ,
    mini: false,
  }),
};
</script>
